import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  label: string
  file?: string
  target?: string
  URL?: string
  onClick?: () => void
  className?: string
  noActions?: boolean
  rel?: string
  variant?: Variant
}

export const Button = memo(function Button({
  label = 'Button',
  file,
  target,
  URL,
  onClick,
  className,
  noActions,
  rel,
  variant = 'default',
}: Props) {
  const buttonClassName = `${className ? className : ''}`
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {file ? (
        <Anchor
          className={buttonClassName}
          href={file}
          target="_blank"
          rel={rel}
          variant={variant}
        >
          {label}
        </Anchor>
      ) : (
        <>
          {!externalURL ? (
            onClick != undefined ? (
              <Anchor
                className={buttonClassName}
                onClick={onClick}
                variant={variant}
              >
                {label}
              </Anchor>
            ) : noActions ? (
              <Static className={buttonClassName} variant={variant}>
                {label}
              </Static>
            ) : (
              <ButtonItem
                to={URL ? URL : '#'}
                className={buttonClassName}
                variant={variant}
              >
                {label}
              </ButtonItem>
            )
          ) : (
            <Anchor
              className={buttonClassName}
              href={URL}
              rel="noopener"
              target={target}
              variant={variant}
            >
              {label}
            </Anchor>
          )}
        </>
      )}
    </>
  )
})

const Style = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 9.25rem;
  height: 3rem;
  background: ${theme.colors.variants.primaryLight};
  color: ${theme.colors.variants.neutralDark2};
  cursor: pointer;
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1875rem;
  padding: 0 2.25rem;
  transition: 0.25s ease-in-out;
  &:hover {
    background: ${theme.colors.variants.primaryDark};
  }
`

const AdvancedStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4.75rem;
  background: ${theme.colors.variants.secondaryLight};
  color: ${theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-top: 3.125rem;
  padding-right: 1.9375rem;
  transition: 0.25s ease-in-out;
  &:hover {
    &:before {
      width: 3.75rem;
      opacity: 0.5;
      margin-right: 1.25rem;
      margin-left: 1.9375rem;
      transform: none;
    }
  }
  &:before {
    content: '';
    width: 2rem;
    height: 0.0625rem;
    background: ${theme.colors.variants.neutralLight4};
    transform: translateX(-1rem);
    transition: 0.25s ease-in-out;
  }

  @media (max-width: 1199px) {
    height: 4.25rem;
    font-size: 1rem;
    line-height: 1.125rem;
  }
`

const InvertStyle = css`
  ${Style}

  background: ${theme.colors.variants.neutralLight4};
  &:hover {
    background: ${theme.colors.variants.neutralDark2};
    color: ${theme.colors.variants.neutralLight4};
  }
`

const OutlineStyle = css`
  ${Style}

  background: none;
  border: 0.0625rem solid ${theme.colors.variants.neutralLight4};
  color: ${theme.colors.variants.neutralLight4};
  &:hover {
    background: ${theme.colors.variants.neutralLight4};
    color: ${theme.colors.variants.neutralDark2};
  }
`

const Anchor = styled.a<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return Style
      case 'advanced':
        return AdvancedStyle
      case 'invert':
        return InvertStyle
      case 'outline':
        return OutlineStyle
    }
  }}
`

const Static = styled.div<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return Style
      case 'advanced':
        return AdvancedStyle
      case 'invert':
        return InvertStyle
      case 'outline':
        return OutlineStyle
    }
  }}
`

const ButtonItem = styled(Link)<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return Style
      case 'advanced':
        return AdvancedStyle
      case 'invert':
        return InvertStyle
      case 'outline':
        return OutlineStyle
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'advanced' | 'invert' | 'outline'
