import * as React from 'react'
import { memo } from 'react'

const SvgCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 15.465 12"
  >
    <path d="M13.703.001a1.712 1.712 0 0 0-1.177.517c-2.672 2.678-4.732 4.923-7.18 7.429l-2.488-2.1A1.712 1.712 0 1 0 .646 8.46l3.71 3.139a1.713 1.713 0 0 0 2.319-.1c3.08-3.086 5.268-5.546 8.277-8.562A1.712 1.712 0 0 0 13.703.003Z" />
  </svg>
)

const Memo = memo(SvgCheck)
export default Memo
