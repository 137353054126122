import * as React from 'react'
import { memo } from 'react'

const SvgPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 31.455 38"
  >
    <g transform="translate(-2)">
      <path d="M32.455 15.727C32.455 27.182 17.727 37 17.727 37S3 27.182 3 15.727a14.727 14.727 0 1 1 29.455 0Z" />
      <circle cx={5} cy={5} r={5} transform="translate(12.898 11.149)" />
    </g>
  </svg>
)

const Memo = memo(SvgPin)
export default Memo
