import * as React from 'react'
import { memo } from 'react'

const SvgDropup = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 11.117 6.559"
  >
    <path d="M1.414 5.145 5.559 1l4.145 4.145" />
  </svg>
)

const Memo = memo(SvgDropup)
export default Memo
