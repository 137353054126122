import * as React from 'react'
import { memo } from 'react'

const SvgChevronsRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 11.811 12.121"
  >
    <path d="m6.061 1.061 5 5-5 5M1.061 1.061l5 5-5 5" />
  </svg>
)

const Memo = memo(SvgChevronsRight)
export default Memo
