import * as React from 'react'
import { memo } from 'react'

const SvgYoutube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28.571 20"
  >
    <path d="M27.974 3.123A3.578 3.578 0 0 0 25.448.597c-2.228-.6-11.163-.6-11.163-.6s-8.934 0-11.163.6A3.578 3.578 0 0 0 .596 3.123a39.9 39.9 0 0 0 0 13.754 3.578 3.578 0 0 0 2.526 2.526c2.228.6 11.163.6 11.163.6s8.935 0 11.163-.6a3.578 3.578 0 0 0 2.526-2.526 39.912 39.912 0 0 0 0-13.754ZM11.431 14.286V5.715l7.423 4.286Z" />
  </svg>
)

const Memo = memo(SvgYoutube)
export default Memo
