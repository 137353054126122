import * as React from 'react'
import { memo } from 'react'

const SvgPlay = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 20"
  >
    <path d="m1 1 14 9-14 9Z" />
  </svg>
)

const Memo = memo(SvgPlay)
export default Memo
