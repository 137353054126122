import * as React from 'react'
import { memo } from 'react'

const SvgIconLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 117.726 90"
  >
    <path d="M83.61 19.318A32.044 32.044 0 0 0 69.23 32.87S55.879 55.65 50.4 67.8a9.2 9.2 0 0 1-6.505 5.233h-.069c-5.279-8.1-2.014-27.308-7.8-37.438C27.508 20.659 0 23.679 0 23.679c25.844 9.134 24.452 18.271 25.939 26.336 1.59 19.5 6.569 23.838 10.807 28.447a1.1 1.1 0 0 0-.1.423 35.645 35.645 0 0 1-3.347 4.294c-1.336 1.28-4.938 1.993-7.034 2.293s-1.444 1.878-1.444 1.878 3.7 2.67 4.717 2.65 4.381-1.952 5.279-2.581 5.279-1.37 14.757-1.672 14.893-7.772 14.893-7.772l7.849-6.21a10.075 10.075 0 0 1 2.614-1.5c3.966-1.534 6.279-2.055 3.958-2.691-1.054-.295-7.375 1.932-8.539 1.624-5.325-1.411-9.922 5.217-10.05 1.965a1.564 1.564 0 0 1 .074-.446 5.9 5.9 0 0 1 .808-1.731C66.952 60.4 83.495 36.15 87.843 33.211 115.638 13.7 117.726 0 117.726 0S93.411 14.7 83.61 19.318Z" />
  </svg>
)

const Memo = memo(SvgIconLogo)
export default Memo
