import * as React from 'react'
import { memo } from 'react'

const SvgInstagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 19.997 20"
  >
    <path d="M5.876 19.941a7.362 7.362 0 0 1-2.425-.465A5.131 5.131 0 0 1 .524 16.55a7.343 7.343 0 0 1-.465-2.426C.013 13.057 0 12.717 0 10s.013-3.054.063-4.124A7.337 7.337 0 0 1 .527 3.45a4.938 4.938 0 0 1 1.154-1.77A4.871 4.871 0 0 1 3.454.522 7.314 7.314 0 0 1 5.882.059C6.947.011 7.288 0 10 0s3.055.011 4.125.062a7.319 7.319 0 0 1 2.428.465 5.117 5.117 0 0 1 2.926 2.927 7.326 7.326 0 0 1 .465 2.425C19.994 6.946 20 7.285 20 10s-.011 3.055-.059 4.122a7.312 7.312 0 0 1-.465 2.426 5.114 5.114 0 0 1-2.927 2.926 7.323 7.323 0 0 1-2.425.465c-1.07.048-1.409.061-4.124.061s-3.056-.013-4.124-.059Zm.095-18.066a5.488 5.488 0 0 0-1.856.344 3.106 3.106 0 0 0-1.149.746 3.022 3.022 0 0 0-.751 1.149 5.49 5.49 0 0 0-.343 1.856c-.047 1.051-.059 1.371-.059 4.04s.011 2.985.059 4.04a5.515 5.515 0 0 0 .343 1.855 3.126 3.126 0 0 0 .746 1.148 3.092 3.092 0 0 0 1.15.746 5.491 5.491 0 0 0 1.856.343c1.051.047 1.371.059 4.04.059s2.985-.011 4.04-.059A5.507 5.507 0 0 0 15.9 17.8a3.321 3.321 0 0 0 1.9-1.9 5.472 5.472 0 0 0 .343-1.855c.047-1.055.059-1.368.059-4.036s-.011-2.985-.059-4.04a5.5 5.5 0 0 0-.343-1.855 3.1 3.1 0 0 0-.748-1.149 3.037 3.037 0 0 0-1.148-.746 5.468 5.468 0 0 0-1.856-.344c-1.051-.047-1.367-.059-4.04-.059s-2.983.012-4.037.059ZM4.866 10A5.137 5.137 0 1 1 10 15.14 5.138 5.138 0 0 1 4.866 10Zm1.8 0A3.333 3.333 0 1 0 10 6.669 3.333 3.333 0 0 0 6.67 10Zm7.474-5.34a1.2 1.2 0 1 1 1.2 1.2 1.2 1.2 0 0 1-1.196-1.199Z" />
  </svg>
)

const Memo = memo(SvgInstagram)
export default Memo
