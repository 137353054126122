import * as React from 'react'
import { memo } from 'react'

const SvgTrain = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24.588 36"
  >
    <path d="m23.2 35.551-1.408-3.472H2.798L1.39 35.551a.723.723 0 0 1-.668.45.709.709 0 0 1-.27-.053.721.721 0 0 1-.4-.938l2.807-6.92a2.707 2.707 0 0 1-2.115-2.636V2.706a2.707 2.707 0 0 1 2.705-2.7h17.689a2.708 2.708 0 0 1 2.705 2.7v22.748a2.709 2.709 0 0 1-2.117 2.636l1.213 2.99 1.6 3.93a.719.719 0 0 1-.4.938.7.7 0 0 1-.27.053.723.723 0 0 1-.669-.45ZM3.382 30.639h17.826L20.2 28.157H4.389Zm.068-3.922h17.689a1.265 1.265 0 0 0 1.264-1.265v-5.659a6.479 6.479 0 0 1-5.051 2.423H7.237a6.476 6.476 0 0 1-5.051-2.423v5.659a1.265 1.265 0 0 0 1.264 1.265ZM2.186 15.724a5.057 5.057 0 0 0 5.053 5.052h10.113a5.056 5.056 0 0 0 5.051-5.052V6.788H2.186Zm0-13.02v2.645h20.217V2.704a1.264 1.264 0 0 0-1.264-1.264H3.45a1.265 1.265 0 0 0-1.264 1.264Zm15.568 22.482a.72.72 0 0 1-.721-.72.72.72 0 0 1 .721-.72h1.658a.718.718 0 0 1 .719.72.718.718 0 0 1-.719.72Zm-12.578 0a.718.718 0 0 1-.719-.72.718.718 0 0 1 .719-.72h1.66a.718.718 0 0 1 .719.72.718.718 0 0 1-.719.72Z" />
  </svg>
)

const Memo = memo(SvgTrain)
export default Memo
