import * as React from 'react'
import { memo } from 'react'

const SvgCar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 36 32.115"
  >
    <path d="M24.297 28.721v-1.377H11.705v1.377c0 4.525-7.672 4.525-7.672 0v-1.574A5.437 5.437 0 0 1 0 22.032v-4.623a5.424 5.424 0 0 1 4.918-5.312L6.59 4.425a5.821 5.821 0 0 1 5.705-4.426h11.41a5.906 5.906 0 0 1 5.707 4.426l1.67 7.672A5.423 5.423 0 0 1 36 17.409v4.623a5.555 5.555 0 0 1-4.033 5.115v1.574c0 2.262-1.918 3.393-3.834 3.393s-3.836-1.131-3.836-3.393Zm1.475 0c0 2.459 4.623 2.459 4.623 0v-1.377h-4.623Zm-20.164 0c0 2.459 4.525 2.459 4.525 0v-1.377H5.608ZM1.575 17.41v4.623a3.846 3.846 0 0 0 3.936 3.738h24.885a3.872 3.872 0 0 0 4.033-3.738V17.41a3.888 3.888 0 0 0-4.033-3.836H5.511a3.864 3.864 0 0 0-3.936 3.835ZM8.067 4.82l-1.574 7.279h23.018L27.839 4.82a4.125 4.125 0 0 0-4.131-3.246h-11.41a4.157 4.157 0 0 0-4.232 3.245Zm16.92 15.049a3.338 3.338 0 0 1 3.441-3.344 3.4 3.4 0 0 1 3.441 3.344 3.4 3.4 0 0 1-3.441 3.344 3.338 3.338 0 0 1-3.442-3.344Zm1.473 0a1.877 1.877 0 0 0 1.969 1.77 1.849 1.849 0 0 0 1.869-1.77 1.849 1.849 0 0 0-1.869-1.771 1.877 1.877 0 0 0-1.97 1.771Zm-22.326 0a3.4 3.4 0 0 1 3.441-3.344 3.4 3.4 0 0 1 3.443 3.344 3.4 3.4 0 0 1-3.443 3.344 3.4 3.4 0 0 1-3.442-3.344Zm1.475 0a1.875 1.875 0 0 0 1.967 1.77 1.789 1.789 0 0 0 1.871-1.77 1.789 1.789 0 0 0-1.871-1.771 1.875 1.875 0 0 0-1.969 1.771Zm8.951 2.754a.789.789 0 0 1 0-1.574h6.787a.787.787 0 1 1 0 1.574Zm0-3.934a.789.789 0 0 1 0-1.574h6.787a.787.787 0 1 1 0 1.574Z" />
  </svg>
)

const Memo = memo(SvgCar)
export default Memo
