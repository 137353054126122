import * as React from 'react'
import { memo } from 'react'

const SvgUsers = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 23.262 18"
  >
    <path d="M11.965 9.233a5.209 5.209 0 0 0 1.8-3.939 5.294 5.294 0 0 0-10.588 0 5.209 5.209 0 0 0 1.8 3.939A8.47 8.47 0 0 0 0 16.941a1.059 1.059 0 0 0 2.118 0 6.353 6.353 0 0 1 12.706 0 1.059 1.059 0 0 0 2.118 0 8.471 8.471 0 0 0-4.976-7.708Zm-3.494-.762a3.177 3.177 0 1 1 2.246-.93 3.177 3.177 0 0 1-2.246.93Zm10.313.339A5.294 5.294 0 0 0 14.824 0a1.059 1.059 0 0 0 0 2.118 3.172 3.172 0 0 1 1.588 5.919 1.058 1.058 0 0 0-.053 1.8l.413.275.138.074a7.412 7.412 0 0 1 4.235 6.755 1.059 1.059 0 0 0 2.118 0 9.529 9.529 0 0 0-4.479-8.132Z" />
  </svg>
)

const Memo = memo(SvgUsers)
export default Memo
