import * as React from 'react'
import { memo } from 'react'

const SvgPhone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 37.931 38.003"
  >
    <path d="M36.929 27.953v5.419a3.613 3.613 0 0 1-3.939 3.613 35.75 35.75 0 0 1-15.59-5.546A35.226 35.226 0 0 1 6.562 20.6 35.75 35.75 0 0 1 1.017 4.938 3.613 3.613 0 0 1 4.611 1h5.419a3.613 3.613 0 0 1 3.613 3.107 23.2 23.2 0 0 0 1.265 5.076A3.613 3.613 0 0 1 14.095 13l-2.294 2.294A28.9 28.9 0 0 0 22.64 26.128l2.294-2.294a3.613 3.613 0 0 1 3.812-.813 23.2 23.2 0 0 0 5.076 1.265 3.613 3.613 0 0 1 3.107 3.667Z" />
  </svg>
)

const Memo = memo(SvgPhone)
export default Memo
