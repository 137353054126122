import * as React from 'react'
import { memo } from 'react'

const SvgMaximize = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
  >
    <path d="M11.406 15.785H3.787l12-12v7.623a1.108 1.108 0 0 0 2.216 0v-10.3A1.108 1.108 0 0 0 16.887 0h-10.3a1.108 1.108 0 1 0 0 2.216h7.63l-12 12V6.594a1.108 1.108 0 0 0-2.216 0v10.3a1.108 1.108 0 0 0 1.108 1.108h10.3a1.108 1.108 0 1 0 0-2.216Z" />
  </svg>
)

const Memo = memo(SvgMaximize)
export default Memo
