import * as React from 'react'
import { memo } from 'react'

const SvgClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 13.838 13.838"
  >
    <path d="m12.919.919-12 12M.919.919l12 12" />
  </svg>
)

const Memo = memo(SvgClose)
export default Memo
