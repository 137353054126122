import * as React from 'react'
import { memo } from 'react'

const SvgStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18.851 18"
  >
    <path d="M4.206 17.982a.965.965 0 0 1-.932-1.269l1.725-5.431-4.6-3.391a.975.975 0 0 1 .575-1.765l5.711-.04 1.8-5.413a.981.981 0 0 1 1.864 0l1.8 5.413 5.711.04a1 1 0 0 1 .932.674.969.969 0 0 1-.357 1.091l-4.6 3.391 1.725 5.433a1 1 0 0 1-.357 1.091.952.952 0 0 1-1.15 0l-4.64-3.331-4.64 3.331a1.035 1.035 0 0 1-.567.176Zm5.215-5.711a1.033 1.033 0 0 1 .575.178l2.8 2-1.055-3.267a1.01 1.01 0 0 1 .357-1.091l2.776-2.042-3.45-.02a1 1 0 0 1-.932-.674L9.401 4.082 8.31 7.354a1 1 0 0 1-.932.674l-3.45.02 2.776 2.042a1.01 1.01 0 0 1 .357 1.092L6.01 14.454l2.8-2a1.275 1.275 0 0 1 .615-.178Z" />
  </svg>
)

const Memo = memo(SvgStar)
export default Memo
