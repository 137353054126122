import * as React from 'react'
import { memo } from 'react'

const SvgChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 8.414 14.828"
  >
    <path d="m1.414 13.414 6-6-6-6" />
  </svg>
)

const Memo = memo(SvgChevronRight)
export default Memo
