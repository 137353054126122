import * as React from 'react'
import { memo } from 'react'

const SvgEmail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 39.004 30.8"
  >
    <path d="M4.993 1h28.8a3.611 3.611 0 0 1 3.6 3.6v21.6a3.611 3.611 0 0 1-3.6 3.6h-28.8a3.611 3.611 0 0 1-3.6-3.6V4.6a3.611 3.611 0 0 1 3.6-3.6Z" />
    <path d="m37.612 4.622-18.11 12.676L1.393 4.622" />
  </svg>
)

const Memo = memo(SvgEmail)
export default Memo
