import * as React from 'react'
import { memo } from 'react'

const SvgPlane = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 34.238 36"
  >
    <path d="M17.114 35.999a1.946 1.946 0 0 1-1.687-1.057 6.356 6.356 0 0 1-.33-.688l-3.76 1.128a2.493 2.493 0 0 1-.717.106 2.453 2.453 0 0 1-1.471-.491 2.455 2.455 0 0 1-1-1.988 2.493 2.493 0 0 1 1.178-2.108l4.342-2.673a54.526 54.526 0 0 1-.59-7.086v-3.194l-8.908 5.283a3.063 3.063 0 0 1-1.316.305 2.833 2.833 0 0 1-1.52-.443 2.866 2.866 0 0 1-1.34-2.435 2.894 2.894 0 0 1 1.416-2.481l11.668-8.162v-.151a17.288 17.288 0 0 1 2.342-8.836A1.99 1.99 0 0 1 17.114.001a1.991 1.991 0 0 1 1.7 1.027 17.278 17.278 0 0 1 2.34 8.836v.15l11.717 8.193a2.866 2.866 0 0 1 1.367 2.45 2.865 2.865 0 0 1-1.34 2.434 2.871 2.871 0 0 1-1.541.45 2.876 2.876 0 0 1-1.232-.279l-8.971-5.316v3.2a54.5 54.5 0 0 1-.59 7.085l4.342 2.674a2.493 2.493 0 0 1 1.178 2.109 2.454 2.454 0 0 1-1 1.987 2.461 2.461 0 0 1-1.473.491 2.516 2.516 0 0 1-.719-.106l-3.756-1.127a7.171 7.171 0 0 1-.33.689 1.953 1.953 0 0 1-1.689 1.057Zm-.379-1.788a.4.4 0 0 0 .76 0 6.446 6.446 0 0 0 .406-.92l.068-.2a18.836 18.836 0 0 0 .529-1.917 54.4 54.4 0 0 0 1.15-10.034V9.711a15.977 15.977 0 0 0-2.131-7.919.516.516 0 0 0-.4-.292.517.517 0 0 0-.4.293 16.014 16.014 0 0 0-2.131 8.07v11.278a52.75 52.75 0 0 0 1.332 10.782c.113.433.229.824.348 1.167l.059.172a6.6 6.6 0 0 0 .415.949Zm6.586-.267a.965.965 0 0 0 .863-.152.97.97 0 0 0 .395-.784.982.982 0 0 0-.465-.832L20.3 29.827c-.018.1-.037.209-.057.313a28.62 28.62 0 0 1-.621 2.694Zm-13.207-1.767a.982.982 0 0 0-.465.832.969.969 0 0 0 .395.784.972.972 0 0 0 .863.152l3.7-1.11a28.2 28.2 0 0 1-.643-2.815l-.035-.191Zm20.707-10.238a1.341 1.341 0 0 0 1.27-.115 1.358 1.358 0 0 0 .643-1.167 1.387 1.387 0 0 0-.68-1.19l-10.9-7.623v4.359Zm-28.6-2.5a1.421 1.421 0 0 0-.727 1.221 1.359 1.359 0 0 0 .643 1.168 1.356 1.356 0 0 0 1.328.082l9.611-5.7v-4.359Z" />
  </svg>
)

const Memo = memo(SvgPlane)
export default Memo
