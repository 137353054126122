import * as React from 'react'
import { memo } from 'react'

const SvgDropdown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 11.118 6.559"
  >
    <path d="m1.414 1.414 4.145 4.145 4.145-4.145" />
  </svg>
)

const Memo = memo(SvgDropdown)
export default Memo
